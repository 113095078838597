import React, { useState, useEffect } from "react";
import { Container, Navbar, Button, Form } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import UserService from '../services/UserService';
import Header from '../components/Header';
import FormSubmit from '../components/FormSubmit';

const serverConfig = require("../config/server.js");

const Report = () => {

  const navigate = useNavigate();

  const date = String(new Date());
  console.log(date);
  const [errors, setErrors] = useState("");
  const [agreementTitle, setAgreementTitle] = useState("");
  const [agreementText, setAgreementText] = useState("");
  const [showAgreementPage, setShowAgreementPage] = useState(false);
  const [inputs, setInputs] = useState({name:UserService.getWelcomeName()});
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const [privilegeSubmitData, setPrivilegeSubmitData] = useState(false);
  const [privilegeAppListRead, setPrivilegeAppListRead] = useState(false);

  const[pageContent, setPageContent] = useState(1);
  const[applicationId, setApplicationId] = useState(null);

  const [token, setToken] = useState(null);
  const getToken = () => {
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, domain:window.location.hostname})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        //handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const [curPageName, setCurPageName] = useState(null);
  const [currentEntityGroupName, setCurrentEntityGroupName] = useState(null);
  const [step, setstep] = useState(1);
  const [refid, setRefid] = useState("");
  const [hpwd, setHpwd] = useState("");
  const [emailid, setEmailid] = useState("");
  const [steps, setSteps] = useState([]);
  const[stateUSLookup, setStateUSLookup] = useState([]);
  const [companyName, setCompanyName] = useState("E. GIL DENTAL, CORP.");
  const [companyJurisdictionCode, setCompanyJurisdictionCode] = useState("us_fl");
  const [openCorpData, setOpenCorpData] = useState({});

  /* const nextStep = (current_page) => {
    console.log(current_page, steps, step, steps[step].group);
    setstep(step + 1);
    if(current_page) {
      setCurPageName(current_page);
      console.log(current_page);
      console.log(steps.findIndex((item) => item.group === current_page));
      setstep((steps.findIndex((item) => item.group === current_page)) + 2);
    } else {
      console.log(current_page, steps, step, steps[step].group);
      console.log(steps.slice(step).filter(el => el.is_disabled === 0)[0]);
      //setCurPageName(steps[step].group);
      setCurPageName(steps.slice(step).filter(el => el.is_disabled === 0)[0].group);
      console.log((steps.findIndex((item) => item.group === steps.slice(step).filter(el => el.is_disabled === 0)[0].group)) + 1);
      setstep((steps.findIndex((item) => item.group === steps.slice(step).filter(el => el.is_disabled === 0)[0].group)) + 1);
    }
  }; */

  const nextStep = () => {
    console.log(steps, step);
    console.log(steps.slice(step), steps.slice(step).find((el) => el.is_disabled === 0).group, steps.slice(step).findIndex((el) => el.is_disabled === 0));
    setCurPageName(steps.slice(step).find((el) => el.is_disabled === 0).group);
    setstep((steps.findIndex((item) => item.group === steps.slice(step).find((el) => el.is_disabled === 0).group)) + 1);
  };

  const prevStep = () => {
    console.log(steps, step);
    setCurPageName(steps.slice(0, step-1).findLast((el) => el.is_disabled === 0).group);
    //setstep(steps.slice(0, step-1).findLastIndex((el) => el.is_disabled === 0) + 1);
    setstep((steps.findIndex((item) => item.group === steps.slice(0, step-1).findLast((el) => el.is_disabled === 0).group)) + 1);
  };

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
    localStorage.clear();
  }

  const bankAgreementDetailsGet = () => {
    axios.post(serverConfig.api_base_url + "bank_agreement_share_details_get",{token: token}).then(response=>{
      console.log('bank_agreement_share_details_get response', response);
      if(response.data.status === 200) {
        if(response.data.items !== null){
          setAgreementTitle(response.data.items.agreement_title);
          setAgreementText(response.data.items.agreement_text);
        } else {
          setAgreementTitle("");
          setAgreementText("");
        }
        if(response.data.show_page === 1){
          setShowAgreementPage(true);
        } else if(response.data.show_page === 0){
          setShowAgreementPage(false);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const customerGroupGet = (current_page) => {
    axios.post(serverConfig.api_base_url + "customer_entity_group_get",{token: token}).then(response=>{
      console.log('customer_entity_group_get response', response);
      if(response.data.status === 200) {
        if(response.data.items !== null){
          let res = response.data.items;
          res.sort(function(a,b){
            return a.seq_no - b.seq_no;
          });
          console.log(res);
          setSteps(res);
          //setCurPageName(res[0].group);
          // setCurPageName('documentation');
          // setstep(2);
          console.log(currentEntityGroupName);
          if(current_page){
            setCurPageName(current_page);
            console.log(res.findIndex((item) => item.group === current_page) + 1);
            setstep(res.findIndex((item) => item.group === current_page) + 1);
          } else if(currentEntityGroupName){
            setCurPageName(currentEntityGroupName);
            console.log(res.findIndex((item) => item.group === currentEntityGroupName) + 1);
            setstep(res.findIndex((item) => item.group === currentEntityGroupName) + 1);
          } else {
            setCurPageName(res[0].group);
          }
          //setCurPageName('filing_information');
          //setstep(1);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    console.log(token);
    console.log(sessionStorage.getItem('token'));
    //setToken(sessionStorage.getItem('token'));

    if(token !== null && token !== undefined){
      //submit_data - is customer
      axios.post(serverConfig.api_base_url + "user_check_privilege",{token: token, privilege: "submit_data"}).then(response=>{
        console.log('user_check_privilege response', response);
        if(response.data.status === 200) {
          if(response.data.is_privileged === 1){
            setPrivilegeSubmitData(true);
            navigate("/customer");
          } else if(response.data.is_privileged === 0) {
            setPrivilegeSubmitData(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      //application_list_read - is admin / banker
      axios.post(serverConfig.api_base_url + "user_check_privilege",{token: token, privilege: "application_list_read"}).then(response=>{
        console.log('user_check_privilege response', response);
        if(response.data.status === 200) {
          if(response.data.is_privileged === 1){
            setPrivilegeAppListRead(true);
            navigate("/applications");
          } else if(response.data.is_privileged === 0) {
            setPrivilegeAppListRead(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      axios.post(serverConfig.api_base_url + "get_lookup_values",{entity_name:"state_us"})
      .then(response=>{
        console.log(response);
        if(response.data.data !== null){
          setStateUSLookup(response.data.data.sort((a, b) => a.value.localeCompare(b.value)));
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token, sessionStorage.getItem('token')]);

  useEffect(() => {
    if(privilegeSubmitData){
      axios.post(serverConfig.api_base_url + "customer_application_list_get",{token: token, application_self: 1}).then(response=>{
        console.log('customer_application_list_get response', response);
        if(response.data.status === 200) {
          if(response.data.items !== null){
            console.log(response.data.items.filter(el => el.status === "Open"))
            setApplicationId(response.data.items.filter(el => el.status === "Open")[0].application_id);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      customerGroupGet();

      bankAgreementDetailsGet();

      const script = document.createElement('script');
      script.src = "https://tfaisuper.blob.core.windows.net/$web/supervity-chat-widget-prod-sync.js?agentId=d96d6d6e-915f-4082-9136-ca61670a64e4&chatWidgetWidth=600&chatFontSize=16px&brandIcon=https://i.ibb.co/D1wnWXW/Amberron-new.png&chatWidgetCloseIconColor=%231d262e&defaultOpen=true&chatWidgetWidth=40vw&chatWidgetMinHeight=55dvh&showUserIcon=true&botIcon=https://i.ibb.co/D1wnWXW/Amberron-new.png&brandIconSize=90&headerMsg1Color=%fff&headerMsg1=Ask%20Manatoko&brandColor=%231d262e";
      script.type = "module";
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      }
    }
  }, [privilegeSubmitData]);

  useEffect(() => {
    if(applicationId !== null && applicationId !== undefined && applicationId !== ''){
      axios.post(serverConfig.api_base_url + "customer_current_entity_group_get",{token: token, application_id: applicationId}).then(response=>{
        console.log('customer_current_entity_group_get response', response);
        if(response.data.status === 200) {
          if(response.data.page_name !== null && response.data.page_name !== undefined){
            setCurrentEntityGroupName(response.data.page_name);
            setPageContent(3);
          } else {
            setCurrentEntityGroupName(null);
            setPageContent(1);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [applicationId]);

  useEffect(() => {
    if(token !== null && token !== undefined){
      customerGroupGet();
    }
  }, [currentEntityGroupName]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [curPageName]);

  const handleSignAgreement = (event) => {
    event.preventDefault();
    if (inputs.agree === "AGREE" || inputs.agree === "DISAGREE") {
      var isAgreed;
      if(inputs.agree === "AGREE"){
        isAgreed = 1;
      } else if(inputs.agree === "DISAGREE"){
        isAgreed = 0;
      }
      axios.post(serverConfig.api_base_url + "bank_agreement_share_details_set",{token: token, name: inputs.name, is_agreed: isAgreed}).then(response=>{
        console.log('bank_agreement_share_details_set response', response);
        if(response.data.status === 200) {
          setErrors("");
          setPageContent(3);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
          setErrors(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    } else {
      setErrors(<>Please enter <b>AGREE</b> or <b>DISAGREE</b></>);
    }
  }

  return (
    <div className="body-wrap bg-color8 pb-3">
      <Header isLoggedIn={(token !== null && token !== undefined && token !== '') ? true : false} />
      {privilegeSubmitData && <>
        {pageContent === 1 && <>
          <main>
            <Container fluid>
              <div className="mb-4 pb-1 border-bottom">
                <h1 className="mb-3">Beneficial Ownership Information Report (BOIR)</h1>
                <p className="fw-500">OMB No. 1506-0076</p>
                <p className="fw-500">Complete the report in its entirety with all required information. Fields marked with an asterisk (<span className="txt-red">*</span>) symbol are the fields that, at a minimum, must be completed by all companies to file the report.</p>
                <p className="fw-500">Reporting companies, however, must include all information 31 CFR 1010.380(b) requires them to provide, regardless of whether a field is marked with an asterisk (<span className="txt-red">*</span>) symbol. {/* These information requirements are summarized above under HELP. */}</p>
              </div>
              <h5>Documents you will need:</h5>
              <ol className="ps-3">
                <li><b>Your passport / driver license</b></li>
                <li><b>Supporting documentation</b><br/>(ie. driver license, government ID, bank statement).</li>
              </ol>
              {/* <div className="info-box mt-4">
                <span className="info-icon"></span>
                <span>You will need just 10 minutes to fill out this form</span>
              </div> */}
            </Container>
          </main>
          <footer className="mt-auto">
            <Container fluid>
              <div className="mt-auto"><Button variant="primary" className="w-100" onClick={() => {showAgreementPage ? setPageContent(2) : setPageContent(3)}}>Got it</Button></div>
            </Container>
          </footer>
        </>}
        {pageContent === 2 && <>
          <Form onSubmit={handleSignAgreement}>
            <main>
              <Container fluid>
                <div className="mb-4 pb-1">
                  <h1 className="mb-3" dangerouslySetInnerHTML={{__html:agreementTitle}} />
                  <p className="mt-5 fw-500" dangerouslySetInnerHTML={{__html:agreementText}} />
                </div>
                <div>
                  <Form.Control 
                    name="agree"
                    value={inputs.agree || ""} 
                    onChange={handleChange} 
                    type="text" 
                    required
                    placeholder="AGREE or DISAGREE"
                  />
                </div>
                <div className="mt-4">
                  <Form.Control 
                    name="name"
                    value={inputs.name || ""} 
                    onChange={handleChange} 
                    type="text" 
                    placeholder="Enter your name"
                    required
                  />
                </div>
                <div className="mt-4">{date}</div>
                <div className="txt-red mt-5">{errors}</div>
              </Container>
            </main>
            <footer className="mt-auto">
              <Container fluid>
                <div className="mt-auto"><Button type="submit" variant="primary" className="w-100">Next</Button></div>
              </Container>
            </footer>
          </Form>
        </>}
        {pageContent === 3 && <>
          <main>
            {(token && curPageName && steps.length > 0) && <>
              <Container fluid>
                {/* <div className="mb-4 pb-1 border-bottom">
                  <h1 className="mb-3">Beneficial Ownership Information Report (BOIR)</h1>
                  <p className="fw-500">OMB No. 1506-0076</p>
                  <p className="fw-500">Reporting companies, however, must include all information 31 CFR 1010.380(b) requires them to provide, regardless of whether a field is marked with an asterisk (<span className="txt-red">*</span>) symbol.</p>
                </div> */}
                <div className='page-title'>
                  <h5>&nbsp;</h5>
                  <span className='step-title'>
                    {steps[step-1].icon && <img src={steps[step-1].icon} className="me-1" />}
                    <b>{steps[step-1].label}</b>
                  </span>
                </div>
                {/* {step}, {curPageName} */}
                <div className='steps-progress'>
                  {steps.map((item, index) => {
                    let curClass = '';
                    if(step-1 === index){
                      curClass = 'active'
                    } else if(index < step){
                      curClass = 'completed'
                    }
                    return(<><div key={index} className={curClass + ' step-point'}><span></span></div>{index < steps.length-1 && <div className="step-connector" style={{width: `calc((100% / ${steps.length-1}) - 33px)`}}></div>}</>)
                  })}
                </div>
              </Container>

              <FormSubmit prevStep={prevStep} nextStep={nextStep} token={token} curPageName={curPageName} stepNum={step}stepLength={steps.length} handleLogout={handleLogout} email_id={emailid} setPageContent={setPageContent} applicationId={applicationId} customerGroupGet={customerGroupGet} bankAgreementDetailsGet={bankAgreementDetailsGet} />
            </>}
          </main>
        </>}
      </>}
    </div>
  );
}

export default Report;