import React, { useState, useEffect } from "react";
import { Container, Navbar, Button, Modal } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import UserService from '../services/UserService';
import Header from '../components/Header';
import FormSubmit from '../components/FormSubmit';

const serverConfig = require("../config/server.js");

const Report = () => {

  const navigate = useNavigate();

  const [privilegeSubmitData, setPrivilegeSubmitData] = useState(false);
  const [privilegeAppListRead, setPrivilegeAppListRead] = useState(false);

  const[pageContent, setPageContent] = useState(1);
  const[applicationId, setApplicationId] = useState(null);

  const [token, setToken] = useState(null);
  const getToken = () => {
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, domain:window.location.hostname})
    //axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, domain:"chia.dev.mtk.dbtez.net"})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        //handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const [curPageName, setCurPageName] = useState(null);
  const [step, setstep] = useState(1);
  const [refid, setRefid] = useState("");
  const [hpwd, setHpwd] = useState("");
  const [emailid, setEmailid] = useState("");
  const [steps, setSteps] = useState([]);

  const nextStep = (current_page) => {
    console.log(current_page, step);
    setstep(step + 1);
    if(current_page) {
      setCurPageName(current_page);
      console.log(current_page);
      console.log(steps.findIndex((item) => item.group === current_page));
      setstep((steps.findIndex((item) => item.group === current_page)) + 2);
    } else {
      setCurPageName(steps[step].group);
      //setCurPageName('agreements_disclosures');
    }
  };

  const prevStep = () => {
    console.log(steps, step);
    setstep(step - 1);
    setCurPageName(steps[step-2].group);
  };

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
    localStorage.clear();
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    console.log(token);
    console.log(sessionStorage.getItem('token'));
    //setToken(sessionStorage.getItem('token'));

    if(token !== null && token !== undefined){
      //submit_data - is customer
      axios.post(serverConfig.api_base_url + "user_check_privilege",{token: token, privilege: "submit_data"}).then(response=>{
        console.log('user_check_privilege response', response);
        if(response.data.status === 200) {
          if(response.data.is_privileged === 1){
            setPrivilegeSubmitData(true);
            navigate("/customer");
          } else if(response.data.is_privileged === 0) {
            setPrivilegeSubmitData(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      //application_list_read - is admin / banker
      axios.post(serverConfig.api_base_url + "user_check_privilege",{token: token, privilege: "application_list_read"}).then(response=>{
        console.log('user_check_privilege response', response);
        if(response.data.status === 200) {
          if(response.data.is_privileged === 1){
            setPrivilegeAppListRead(true);
            navigate("/applications");
          } else if(response.data.is_privileged === 0) {
            setPrivilegeAppListRead(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token, sessionStorage.getItem('token')]);

  useEffect(() => {
    if(privilegeSubmitData){
      axios.post(serverConfig.api_base_url + "customer_entity_group_get",{token: token}).then(response=>{
        console.log('customer_entity_group_get response', response);
        if(response.data.status === 200) {
          if(response.data.items !== null){
            let res = response.data.items;
            res.sort(function(a,b){
              return a.seq_no - b.seq_no;
            });
            console.log(res);
            setSteps(res);
            //setCurPageName(res[0].group);
            // setCurPageName('documentation');
            // setstep(2);
            if(sessionStorage.getItem('curPage')){
              setCurPageName(sessionStorage.getItem('curPage'));
              console.log(res.findIndex((item) => item.group === sessionStorage.getItem('curPage')) + 1);
              setstep(res.findIndex((item) => item.group === sessionStorage.getItem('curPage')) + 1);
            } else {
              setCurPageName(res[0].group);
            }
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      axios.post(serverConfig.api_base_url + "customer_application_list_get",{token: token}).then(response=>{
        console.log('customer_application_list_get response', response);
        if(response.data.status === 200) {
          if(response.data.items !== null){
            setApplicationId(response.data.items[0]);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [privilegeSubmitData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [curPageName]);

  return (
    <div className="body-wrap bg-color8 pb-3">
      <Header isLoggedIn={(token !== null && token !== undefined && token !== '') ? true : false} />
      {privilegeSubmitData && <>
        {pageContent === 1 && <>
          <main>
            <Container fluid>
              <div class="mb-4 pb-1 border-bottom">
                <h1 className="mb-3">Beneficial Ownership Information Report (BOIR)</h1>
                <p className="fw-500">OMB No. 1506-0076</p>
                <p className="fw-500">Complete the report in its entirety with all required information. Fields marked with an asterisk (<span className="txt-red">*</span>) symbol are the fields that, at a minimum, must be completed by all companies to file the report. Reporting companies, however, must include all information 31 CFR 1010.380(b) requires them to provide, regardless of whether a field is marked with an asterisk (<span className="txt-red">*</span>) symbol. These information requirements are summarized above under HELP.</p>
              </div>
              <h5>Documents you will need:</h5>
              <ol className="ps-3">
                <li><b>Your driver license</b></li>
                <li><b>Supporting documentation</b><br/>(ie. driver license, government ID, bank statement).</li>
              </ol>
              <div className="info-box mt-4">
                <span className="info-icon"></span>
                <span>You will need just 10 minutes to fill out this form</span>
              </div>
            </Container>
          </main>
          <footer className="mt-auto">
            <Container fluid>
              <div className="mt-auto"><Button variant="primary" className="w-100" onClick={() => setPageContent(2)}>Got it</Button></div>
            </Container>
          </footer>
        </>}
        {pageContent === 2 && <>
          <main>
            {(token && curPageName && steps.length > 0) && <>
              <Container fluid>
                {/* <div class="mb-4 pb-1 border-bottom">
                  <h1 className="mb-3">Beneficial Ownership Information Report (BOIR)</h1>
                  <p className="fw-500">OMB No. 1506-0076</p>
                  <p className="fw-500">Reporting companies, however, must include all information 31 CFR 1010.380(b) requires them to provide, regardless of whether a field is marked with an asterisk (<span className="txt-red">*</span>) symbol.</p>
                </div> */}
                <div className='page-title'>
                  <h5>Prepare BOIR</h5>
                  <span className='step-title'>
                    {steps[step-1].icon && <img src={steps[step-1].icon} className="me-1" />}
                    <span className="fw-500">{steps[step-1].label}</span>
                  </span>
                </div>
                {/* {step}, {curPageName} */}
                <div className='steps-progress'>
                  {steps.map((item, index) => {
                    let curClass = '';
                    if(step-1 === index){
                      curClass = 'active'
                    } else if(index < step){
                      curClass = 'completed'
                    }
                    return(<><div key={index} className={curClass + ' step-point'}><span></span></div>{index < steps.length-1 && <div className="step-connector" style={{width: `calc((100% / ${steps.length-1}) - 33px)`}}></div>}</>)
                  })}
                </div>
              </Container>

              <FormSubmit prevStep={prevStep} nextStep={nextStep} token={token} curPageName={curPageName} stepNum={step}stepLength={steps.length} handleLogout={handleLogout} email_id={emailid} setPageContent={setPageContent} applicationId={applicationId} />
            </>}
          </main>
        </>}
      </>}
    </div>
  );
}

export default Report;