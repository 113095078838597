import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import Header from "../../components/Header";

const Login = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [errorText, setErrorText] = useState("");

  return (
    <>
      <Header isLoggedIn={false} />
      <main className="main-wrap">
        <section className="signup-section">
          <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <Row className="w-100">
              <Col className="d-flex flex-column align-items-center">
                <h1>Log In</h1>
                {errorText !== "" && (
                  <p className="error-display">{errorText}</p>
                )}
                <Form className="w-100" style={{ maxWidth: '400px' }}>
                  <Form.Group controlId="formName">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter your Email"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {nameError && <p className="error-text">{nameError}</p>}
                  </Form.Group>
                  <Form.Group controlId="formEmail" className="mt-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter your password"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {emailError && <p className="error-text">{emailError}</p>}
                  </Form.Group>
                  <Button variant="primary" className="mt-4 mb-4 w-100">
                    Log In
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  );
};

export default Login;
