import React, { useState } from 'react';
import { useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import { Button, Form, Row, Col } from 'react-bootstrap';
import axios from "axios";
import UserService from './../services/UserService';

const serverConfig = require("./../config/server.js");

export default function CreditCard({handlePaymentSet, token, handleLogout, amount}) {

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeValid, setCouponCodeValid] = useState(false);
  const [amountAfterDiscount, setAmountAfterDiscount] = useState(null);

  const checkCouponCode = () => {
    console.log(couponCode);
    axios.post(serverConfig.api_base_url + "validate_coupon",{token: token, /* coupon_name:"100 percent off",  */coupon_code:couponCode})
      .then(response=>{
        console.log('validate_coupon', response);
        if(response.data.status === 200) {
          if(response.data.valid === 1){
            setCouponCodeValid(true);
          } else if(response.data.valid === 0){
            setCouponCodeValid(false);
          }
          setAmountAfterDiscount(response.data.amountAfterDiscount);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const billing_details = {
      name: UserService.getWelcomeName(),
      email: UserService.getUsername(),
    };

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details,
    });
    if (error) {
      console.error('Error creating payment method:', error);
      setMessage(error.message);
      setIsProcessing(false);
      return;
    }
    try {
      const response = await fetch(serverConfig.fapi_base_url + 'submit_payment', {
        method: 'POST',
        //headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: UserService.getWelcomeName(),
          email: UserService.getUsername(),
          //name: "Ranjith5d2v23456",
          //email: "ranjith5d2v123456@gmail.com",
          address: {line1: "house no. 1", city:"Bangalore", state: "Karnataka", postal_code: "560078", country: "IN"},//comment this for US stripe account setup
          payment_method: paymentMethod.id,
          promotion_code: couponCode || undefined, // Optional promotion code
        }),
      });
      const data = await response.json();
      if (data.error) {
        console.error('Error creating subscription:', data.error);
        setMessage(data.error);
      } else if (data.clientSecret) {
        const result = await stripe.confirmCardPayment(data.clientSecret);
        if (result.error) {
          console.error('Error confirming card payment:', result.error);
          setMessage(result.error.message);
        } else {
          console.log('Payment successful:', result.paymentIntent);
          setMessage('Payment successful');
          handlePaymentSet(data.subscription_ID, couponCodeValid ? 1 : 0, amountAfterDiscount, couponCode); // Callback to handle post-payment actions
        }
      } else if (data.message) {
        console.log(data.message);
        if(data.message === "Invoice already paid"){
          console.log('Payment successful:', "Invoice already paid");
          setMessage('Payment successful');
          //handlePaymentSet(data.subscriptionID, true);
          handlePaymentSet(data.subscriptionID, 1, amountAfterDiscount, couponCode);
        } else {
          setMessage(data.message);
        }
      }
    } catch (error) {
      console.error('Error handling request:', error);
      setMessage('An unexpected error occurred.');
    }

    setIsProcessing(false);
  };
  
  return (
    <div id="payment-form">
      <div className='mb-3'>
        <h5>Contacts</h5>
        <Form.Group className="mb-3">
          <Form.Label>Name <span className='txt-red'>*</span></Form.Label>
          <Form.Control type="text" readOnly={true} value={UserService.getWelcomeName()} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Email <span className='txt-red'>*</span></Form.Label>
          <Form.Control type="email" readOnly={true} value={UserService.getUsername()} />
        </Form.Group>
        
        <h5 className="mt-4">Payment Information</h5>
        <Form.Group className="mb-3">
          <Form.Label>Coupon Code</Form.Label>
          <Row className='align-items-center'>
            <Col>
              <Form.Control
                type="text"
                name="coupon_code"
                className='custom-input'
                value={couponCode}
                onChange={(e) => {setCouponCodeValid(false); setAmountAfterDiscount(null); setCouponCode(e.target.value);}}
              />
            </Col>
            <Col xs="auto"><Button type="button" variant="transparent" className='p-2' onClick={checkCouponCode} disabled={couponCode === ""}>Apply</Button></Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <CardElement id="payment-element" />
        </Form.Group>
      </div>

      {/* Show any error or success messages */}
      {message && <div id="payment-message" className="mt-3 txt-red">{message}</div>}

      <div className="subscription-box p-4 mt-4">
        <h5>Annual Subscription</h5>
        <p>{couponCodeValid ? <><s>$ {amount}{/*  / Per year */}</s> $ {amountAfterDiscount}{/*  / Per year */}</> : <>$ {amount}{/*  / Per year */}</>}</p>
        <div className="info-box">
          <span className="info-icon"></span>
          <span>Anything can be updated within one year.</span>
        </div>
        <div className="payment-btn-container mt-3"><Button type="button" variant="primary" className="w-100" disabled={isProcessing || !stripe || !elements} id="submit" onClick={handleSubmit}>{isProcessing ? "Processing ... " : "Submit Payment"}</Button></div>
      </div>
    </div>
  );
}