import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import UserService from '../services/UserService';
import Header from "../components/Header";

const serverConfig = require("../config/server.js");

const Landing = () => {
  const navigate = useNavigate();

  const [privilegeSubmitData, setPrivilegeSubmitData] = useState(false);
  const [privilegeAppListRead, setPrivilegeAppListRead] = useState(false);

  const [token, setToken] = useState(null);
  const getToken = () => {
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, domain:window.location.hostname})
    //axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, domain:"chia.dev.mtk.dbtez.net"})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
    localStorage.clear();
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);

  useEffect(() => {
    console.log(token);
    console.log(sessionStorage.getItem('token'));
    //setToken(sessionStorage.getItem('token'));

    if(token !== null && token !== undefined){
      //submit_data - is customer
      axios.post(serverConfig.api_base_url + "user_check_privilege",{token: token, privilege: "submit_data"}).then(response=>{
        console.log('user_check_privilege response', response);
        if(response.data.status === 200) {
          if(response.data.is_privileged === 1){
            setPrivilegeSubmitData(true);
            navigate("/customer");
          } else if(response.data.is_privileged === 0) {
            setPrivilegeSubmitData(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      //application_list_read - is admin / banker
      axios.post(serverConfig.api_base_url + "user_check_privilege",{token: token, privilege: "application_list_read"}).then(response=>{
        console.log('user_check_privilege response', response);
        if(response.data.status === 200) {
          if(response.data.is_privileged === 1){
            setPrivilegeAppListRead(true);
            navigate("/applications");
          } else if(response.data.is_privileged === 0) {
            setPrivilegeAppListRead(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [token, sessionStorage.getItem('token')]);

  return (<>
    {/* <Header isLoggedIn={false} />
    <main className="main-wrap">
        <section className="welcome-text">
          <Container>
            <Row xs={1} md={2} className="justify-content-between align-items-center">
              <Col>
                <div>
                  <img src={require('../assets/images/welcome.png')} alt="Welcome" className="img-fluid" />
                </div>
              </Col>
              <Col>
                <div>
                  <h1>Welcome to BOIR</h1>
                  <p>Marketing description long text example.<br/> Marketing description text</p>
                  <Button onClick={() => navigate("/customer")} className="mt-2">Get started</Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="welcome-box bg-white pt-3">
          <Container>
            <Row xs={1} md={3} className="h-100 align-items-center">
              <Col>
                <div className="item d-flex d-md-block align-items-center justify-content-center">
                  <div className="image"><img src={require('../assets/images/welcome-img-1.png')} alt="Welcome image" className="img-fluid" /></div>
                  <div className="details">
                    <h3>Benefit title</h3>
                    <p>Benefit description long text example.<br/> Benefit description text</p>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="item d-flex d-md-block align-items-center justify-content-center">
                  <div className="image"><img src={require('../assets/images/welcome-img-2.png')} alt="Welcome image" className="img-fluid" /></div>
                  <div className="details">
                    <h3>Benefit title</h3>
                    <p>Benefit description long text example.<br/> Benefit description text</p>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="item d-flex d-md-block align-items-center justify-content-center">
                  <div className="image"><img src={require('../assets/images/welcome-img-3.png')} alt="Welcome image" className="img-fluid" /></div>
                  <div className="details">
                    <h3>Benefit title</h3>
                    <p>Benefit description long text example.<br/> Benefit description text</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
    </main> */}
  </>);
};

export default Landing;
