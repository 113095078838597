import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import Header from "../../components/Header";

const RegisterUser = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({ confirmPassword: "" });

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (confirmPassword && newPassword !== confirmPassword) {
      setErrors({ confirmPassword: "Passwords do not match" });
    } else {
      setErrors({ confirmPassword: "" });
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    if (password !== newConfirmPassword) {
      setErrors({ confirmPassword: "Passwords do not match" });
    } else {
      setErrors({ confirmPassword: "" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Password match validation
    if (password !== confirmPassword) {
      setErrors({ confirmPassword: "Passwords do not match" });
      return;
    }

    // Clear previous errors
    setErrors({ confirmPassword: "" });

    // Further logic for handling form submission
    // Example: Send data to backend, navigate to next step, etc.
  };

  return (
    <>
      <Header isLoggedIn={false} />
      <main className="main-wrap">
        <section className="signup-section">
          <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <Row className="w-100">
              <Col className="d-flex flex-column align-items-center">
                <h1>Set Password</h1>
                <Form className="w-100" style={{ maxWidth: '400px' }} onSubmit={handleSubmit}>
                  <Form.Group controlId="formPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter new password"
                      value={password}
                      onChange={handlePasswordChange}
                      isInvalid={!!errors.confirmPassword}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.confirmPassword}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formConfirmPassword" className="mt-3">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Confirm your password"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      isInvalid={!!errors.confirmPassword}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.confirmPassword}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button variant="primary" className="mt-4 mb-4 w-100" type="submit">
                    Submit
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  );
};

export default RegisterUser;
