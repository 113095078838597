import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";
import axios from "axios";
import { MDBDataTable } from "mdbreact";

// const serverConfig = require("../config/server.js");

const Applications = ({ isUserListReadPrivileged, handleLogout }) => {
  //Invite User Code
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  const handleShow = () => setShowModal(true);

  const handleClose = () => {
    setShowModal(false);
    setFormData({});
    setErrors({});
  };

  const handleInviteFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = () => {
    let formErrors = {};
    if (!validateEmail(formData.email)) {
      formErrors.email = "Please enter a valid email address";
    }

    if (Object.keys(formErrors).length === 0) {
      // Handle invite logic here
      console.log(formData);
      handleClose();
    } else {
      setErrors(formErrors);
    }
  };

  // Code for the Applications
  const mToken = sessionStorage.getItem("mToken");

  const [showInvitePopup, setShowInvitePopup] = useState(false);
  const handleCloseInvitePopup = () => {
    setInputs({});
    setShowInvitePopup(false);
  };
  const handleShowInvitePopup = () => setShowInvitePopup(true);

  const [inviteSentMessage, setInviteSentMessage] = useState("");
  const [showAlreadySentPopup, setShowAlreadySentPopup] = useState(false);
  const handleCloseAlreadySentPopup = () => setShowAlreadySentPopup(false);
  const handleShowAlreadySentPopup = () => setShowAlreadySentPopup(true);

  const [applicationCount, setApplicationCount] = useState({});
  const [showApplications, setShowApplications] = useState("all");
  const [sendInviteDisabled, setSendInviteDisabled] = useState(true);
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    // Refresh app data grid on load of the page - sometimes app data grid doesn't refresh
    setShowApplications("");
    setTimeout(() => {
      setShowApplications("all");
    }, 100);
  }, []);

  useEffect(() => {
    if (mToken !== null && mToken !== undefined) {
      // Check user privilege
      axios
        .post("serverConfig.api_base_url" + "check_user_privilege", {
          token: mToken,
          privilege: "create_invite",
        })
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data.is_privileged === 0) {
              setSendInviteDisabled(true);
            } else if (response.data.is_privileged === 1) {
              setSendInviteDisabled(false);
            }
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch((err) => console.log("error is", err));

      // Application Counts
      axios
        .post("serverConfig.api_base_url" + "get_app_count", {
          token: mToken,
          privilege: "create_invite",
        })
        .then((response) => {
          if (response.data.status === 200) {
            setApplicationCount(JSON.parse(response.data.output));
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch((err) => console.log("error is", err));
    }
  }, [mToken, showApplications]);

  const handleSendInvite = (e) => {
    e.preventDefault();
    var formdata = { token: mToken, ...inputs, force_flag: 0 };
    axios
      .post("serverConfig.api_base_url" + "create_invite", formdata)
      .then((response) => {
        if (response.data.status === 200) {
          handleCloseInvitePopup();
        } else if (response.data.status === 210) {
          setInviteSentMessage(response.data.message);
          handleShowAlreadySentPopup();
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const handleProceedSendInvite = (e) => {
    e.preventDefault();
    var formdata = { token: mToken, ...inputs, force_flag: 1 };
    axios
      .post("serverConfig.api_base_url" + "create_invite", formdata)
      .then((response) => {
        if (response.data.status === 200) {
          handleCloseAlreadySentPopup();
          handleCloseInvitePopup();
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const refreshAppList = (e) => {
    axios
      .post("serverConfig.api_base_url" + "get_app_list", {
        filter_check: "all",
        token: mToken,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setShowApplications("");
          setTimeout(() => {
            setShowApplications("all");
          }, 100);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  const dummyData = [
    {
      id: 1,
      name: "John Doe",
      email: "john.doe@example.com",
      phone: "123-456-7890",
      status: "Completed",
    },
    {
      id: 2,
      name: "Jane Smith",
      email: "jane.smith@example.com",
      phone: "234-567-8901",
      status: "Under Review",
    },
    {
      id: 3,
      name: "Mike Johnson",
      email: "mike.johnson@example.com",
      phone: "345-678-9012",
      status: "Unassigned",
    },
    {
      id: 4,
      name: "Emily Davis",
      email: "emily.davis@example.com",
      phone: "456-789-0123",
      status: "Completed",
    },
    {
      id: 5,
      name: "David Wilson",
      email: "david.wilson@example.com",
      phone: "567-890-1234",
      status: "Under Review",
    },
  ];

  const data = {
    columns: [
      { label: "ID", field: "id", sort: "asc" },
      { label: "Name", field: "name", sort: "asc" },
      { label: "Email", field: "email", sort: "asc" },
      { label: "Phone", field: "phone", sort: "asc" },
      { label: "Status", field: "status", sort: "asc" },
    ],
    rows: dummyData,
  };

  return (
    <div>
      <div className="page-heading">
        <h1>Applications</h1>
        <Button onClick={handleShow} className="mt-2">
          Invite User
        </Button>
        <Modal show={showModal} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Invite User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formFullName">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  name="fullName"
                  value={formData.fullName || ""}
                  onChange={handleInviteFormChange}
                  placeholder="Enter full name"
                />
              </Form.Group>
              <Form.Group controlId="formEmail" className="mt-3">
                <Form.Label>Email ID</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email || ""}
                  onChange={handleInviteFormChange}
                  placeholder="Enter email"
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formMobileNumber" className="mt-3">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  name="mobileNumber"
                  value={formData.mobileNumber || ""}
                  onChange={handleInviteFormChange}
                  placeholder="Enter mobile number"
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="white" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Send Invite
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <>
        <section className="pt-4">
          <Row className="application-stats">
            {isUserListReadPrivileged ? (
              <>
                <Col>
                  <div
                    className="inner"
                    onClick={() => setShowApplications("all")}
                  >
                    <h4>Total Applications</h4>
                    <div className="count-wrap">
                      <span className="count">
                        <span>
                          {"total" in applicationCount
                            ? applicationCount.total
                            : "0"}
                        </span>
                        Nos.
                      </span>
                      <span>
                        <img
                          src="https://via.placeholder.com/50"
                          alt="Application icon"
                        />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div
                    className="inner"
                    onClick={() => setShowApplications("unassigned")}
                  >
                    <h4>Unassigned</h4>
                    <div className="count-wrap">
                      <span className="count">
                        <span>
                          {"unassigned" in applicationCount
                            ? applicationCount.unassigned
                            : "0"}
                        </span>
                        Nos.
                      </span>
                      <span>
                        <img
                          src="https://via.placeholder.com/50"
                          alt="New Applications icon"
                        />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div
                    className="inner"
                    onClick={() => setShowApplications("under_cpa_review")}
                  >
                    <h4>Under Review by Tax Preparer</h4>
                    <div className="count-wrap">
                      <span className="count">
                        <span>
                          {"under_cpa_review" in applicationCount
                            ? applicationCount.under_cpa_review
                            : "0"}
                        </span>
                        Nos.
                      </span>
                      <span>
                        <img
                          src="https://via.placeholder.com/50"
                          alt="New Applications icon"
                        />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div
                    className="inner"
                    onClick={() => setShowApplications("completed")}
                  >
                    <h4>Completed</h4>
                    <div className="count-wrap">
                      <span className="count">
                        <span>
                          {"completed" in applicationCount
                            ? applicationCount.completed
                            : "0"}
                        </span>
                        Nos.
                      </span>
                      <span>
                        <img
                          src="https://via.placeholder.com/50"
                          alt="Approved Application icon"
                        />
                      </span>
                    </div>
                  </div>
                </Col>
              </>
            ) : (
              <>
                <Col>
                  <div
                    className="inner"
                    onClick={() => setShowApplications("all")}
                  >
                    <h4>Applications Assigned to Me</h4>
                    <div className="count-wrap">
                      <span className="count">
                        <span>
                          {"total" in applicationCount
                            ? applicationCount.total
                            : "0"}
                        </span>
                        Nos.
                      </span>
                      <span>
                        <img
                          src="https://via.placeholder.com/50"
                          alt="Application icon"
                        />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div
                    className="inner"
                    onClick={() => setShowApplications("under_review")}
                  >
                    <h4>Applications Under Review</h4>
                    <div className="count-wrap">
                      <span className="count">
                        <span>
                          {"under_review" in applicationCount
                            ? applicationCount.under_review
                            : "0"}
                        </span>
                        Nos.
                      </span>
                      <span>
                        <img
                          src="https://via.placeholder.com/50"
                          alt="New Applications icon"
                        />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div
                    className="inner"
                    onClick={() => setShowApplications("completed")}
                  >
                    <h4>Applications Completed</h4>
                    <div className="count-wrap">
                      <span className="count">
                        <span>
                          {"completed" in applicationCount
                            ? applicationCount.completed
                            : "0"}
                        </span>
                        Nos.
                      </span>
                      <span>
                        <img
                          src="https://via.placeholder.com/50"
                          alt="Approved Application icon"
                        />
                      </span>
                    </div>
                  </div>
                </Col>
              </>
            )}
          </Row>
        </section>
        <section>
          <Modal
            show={showInvitePopup}
            onHide={handleCloseInvitePopup}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Form onSubmit={handleSendInvite}>
              <Modal.Header closeButton>
                <Modal.Title>New User Invite</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={inputs.name || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    name="app_email_id"
                    value={inputs.app_email_id || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Mobile No."
                    name="phone_number"
                    value={inputs.phone_number || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="transparent" onClick={handleCloseInvitePopup}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" className="ms-3">
                  <img
                    src="https://via.placeholder.com/30"
                    alt="Sent Invite Icon"
                    className="me-2"
                  />
                  Send Invite
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <Modal
            show={showAlreadySentPopup}
            onHide={handleCloseAlreadySentPopup}
            backdrop="static"
            keyboard={false}
            centered
            className="content-text"
          >
            <Modal.Body>
              <p>{inviteSentMessage}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="transparent"
                onClick={handleCloseAlreadySentPopup}
              >
                No
              </Button>
              <Button
                variant="primary"
                className="ms-3"
                onClick={handleProceedSendInvite}
              >
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
        </section>
        <section>
          <div className="datatable datatable-app-list">
            <h2>
              Total Applications{" "}
              <button className="ms-3" onClick={refreshAppList}>
                <i className="fas fa-sync"></i>
              </button>
            </h2>
            <MDBDataTable striped bordered hover data={data} />
          </div>
        </section>
      </>
    </div>
  );
};

export default Applications;
