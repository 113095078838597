import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Button } from "react-bootstrap";

export default function CreditCard({handlePaymentSet}) {

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    console.log(window.location.origin);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/kyc-verification`
      },
      redirect: "if_required"
    });

    /* if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    } */

    console.log(error);
    console.log(paymentIntent);
    if(error){
      setMessage(error.message);
    } else if(paymentIntent && paymentIntent.status === 'succeeded'){
      setMessage("Payment status :"+ paymentIntent.status);
      handlePaymentSet();
    } else {
      setMessage("Unexpected state");
    }

    setIsProcessing(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      {message && <div id="payment-message" className="mt-3 txt-red">{message}</div>}
      <div className="payment-btn-container mt-3"><Button type="submit" variant="primary" disabled={isProcessing || !stripe || !elements} id="submit">{isProcessing ? "Processing ... " : "Submit Payment"}</Button></div>
      {/* Show any error or success messages */}
    </form>
  );
}
