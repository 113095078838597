import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
import UserService from "../../services/UserService.js";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const serverConfig = require("../../config/server.js");

const UserRegistration = () => {
  const searchParams = new URLSearchParams(window.location.search);

  const org_slug = searchParams.get("id");
  console.log(searchParams.get(org_slug));

  const [logoBase64Text, setLogoBase64Text] = useState('');
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({});
  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const handleRegister = (event) => {
    event.preventDefault();
    console.log(inputs);
    axios.post(serverConfig.api_base_url + "user_signup",{name: inputs.name, email_id: inputs.email_id, org: org_slug})
    .then(response=>{
      console.log('user_signup response', response);
      if(response.data.status === 200) {
        setResponseMessage("Please check your email to proceed with the registration process");
        setInputs({});
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    axios.post(serverConfig.api_base_url + "get_logo_image",{domainName:window.location.host})
    .then(response=>{
      console.log('get_logo_image response', response);
      if(response.data.status === 200) {
        setLogoBase64Text(response.data.logoBase64);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }, []);

  return (
    <>
      <div className="login">
        <div className="login-container">
          <div className="login-left">
            <img src={require("../../assets/images/login.jpg")} alt="Login Banner" height={300} width={500} />
          </div>
          <div className="form-container">
            <div className="signup-form-wrapper">
              <div className="kc-content">
                <div className="kc-content-wrapper">
                  <div className="kc-form">
                    <div className="kc-form-image">
                      <img
                        src={logoBase64Text}
                        alt="Logo"
                        className="img-fluid"
                      />
                    </div>
                    <Form onSubmit={handleRegister}>
                      <div className="flex flex-col">
                        <Form.Group>
                          <Form.Control
                            name="name"
                            className="signup-input"
                            type="text"
                            placeholder="Enter your name"
                            value={inputs.name || ""}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>
                      </div>
                      <div className="flex flex-col mt-3">
                        <Form.Group>
                          <Form.Control
                            name="email_id"
                            className="signup-input"
                            type="email"
                            placeholder="Enter your email"
                            value={inputs.email_id || ""}
                            onChange={handleChange}
                            required
                          />
                        </Form.Group>
                      </div>
                      <div className="kc-form-buttons mt-4 mb-4">
                        <Button
                          variant="primary"
                          className="signup-submit-btn"
                          type="submit"
                        >
                          Register
                        </Button>
                      </div>
                      <div class='redirect-signup'>
                                <div class='redirect-text'>Already Registered?</div> <button onClick={()=>{ navigate("/customer")} } class='login-btn'>LOG IN</button>
                                </div>
                      <p className="txt-green">{responseMessage ? responseMessage : <>&nbsp;</>}</p>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
            <div className="disclaimer">
            This computer network belongs to Amberoon Inc. and may be used only by Amberoon employees and Customers only for work-related purposes. Amberoon Inc reserves the right to monitor use of this network to ensure network security and to respond to specific allegations of user misuse. Use of this network shall constitute consent to monitoring for such purposes. In addition, the Amberoon Inc reserves the right to consent to a valid law enforcement request to search the network for evidence of a crime stored within the network.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserRegistration;
